<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'告警信息'" />
    <!-- <TJDetailMenu :dataList="dataListB" /> -->
    <TJDetailMenu v-for="(item,index) in dataListC" :key="index" :dataList="item" />

  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'ReportsDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      detailData: {},
      fit: '',
      url: '',
      dataList: [
        {
          name: '医院',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '机号',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '仪器型号',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '位置',
          value: '',
          width: '25%',
          rightBorder: false,
          bottomBorder: false,
        },
      ],
      dataListB: [
        {
          name: '告警名称',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '告警代码',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '告警原因',
          value: '',
          width: '33%',
          rightBorder: false,
          bottomBorder: false,
        },
        {
          name: '处理方法',
          value: '',
          width: '100%',
          bottomBorder: false,
        },
      ],
      dataListC: []
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitrecordwarningDetail()
  },
  methods: {
    gitrecordwarningDetail() {
      apiDetail
        .recordwarningDetail({ record_warning_id: this.detailId })
        .then((res) => {
          this.detailData = res.data
          this.dataList[0].value = this.detailData.hospital_name
          this.dataList[1].value = this.detailData.instrument_id
          this.dataList[2].value = this.detailData.platform_unique
          this.dataList[3].value = this.detailData.hos_pos_name

          this.dataListC = this.detailData.warning_data.map(item=>{
              let dataListB = [...this.dataListB]
              dataListB[0].value = item.warning_name;
              dataListB[1].value = item.warning;
              dataListB[2].value = item.warning_rule;
              dataListB[3].value = item.user_info;
              return dataListB;
          })

          // this.dataListB[0].value = this.detailData.warning_data
          //   ? this.detailData.warning_data.warning_name
          //   : '-'
          // this.dataListB[1].value = this.detailData.warning_data
          //   ? this.detailData.warning_data.warning_id
          //   : '-'
          // this.dataListB[2].value = this.detailData.warning_data
          //   ? this.detailData.warning_data.warning_name
          //   : '-'
          // this.dataListB[3].value = this.detailData.warning_data
          //   ? this.detailData.warning_data.user_info
          //   : '-'
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
